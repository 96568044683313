import { api } from "../fetch";
import { BackpackReq, FoodsUseReq, FoodsUseRes } from "./foods-type";

export class FoodsApi {
  /**
   * 获取背包物品
   */
  static getUseFoods(query?: BackpackReq) {
    return api.get<FoodsUseRes>('foods/backpack', query)
  }

  /**
   * 使用物品
   */
  static postUseFoods(data?: FoodsUseReq) {
    return api.post<FoodsUseRes>('foods/use', data)
  }
}