import React from 'react'

interface Props extends React.HTMLAttributes<HTMLImageElement> {}
const FishToken = (props: Props) => {
  const { className } = props

  return (
    <img src="/images/fish-coin.png" alt="fish" className={`w-6 h-6 inline-block ${className}`} />
  )
}

export default FishToken
