import { api } from '../fetch'
import { BankHashListRes, BankLogReq, BankMineReq, BankMineRes, BankRechargeReq, BankReq, BankRes } from './bank-type'

export class BankApi {
  /**
   * 获取可充值的代币
   */
  static getBank(query?: BankReq) {
    return api.get<BankRes>('bank', query)
  }

  /**
   * 提交充值的hash
   */
  static postBankreCharge(query?: BankRechargeReq) {
    return api.post<BankRes>('bank/recharge', query)
  }

  /**
   * 提交提现的hash POST
   */
  static postBankreWithdraw(query?: BankRechargeReq) {
    return api.post('bank/withdraw', query)
  }

  /**
   * 获取游戏中我的代币信息
   */
  static getBankreMine(query?: BankMineReq) {
    return api.get<BankMineRes>('bank/mine', query)
  }

  /**
   * 获取指定代币的交易历史
   */
  static getBankreLog(query?: BankLogReq) {
    return api.get<BankMineRes>('bank/log', query)
  }

  /**
   * 获取最新的hash
   */
  static getBankHashList() {
    return api.get<BankHashListRes[]>('bank/hash/list')
  }
}
