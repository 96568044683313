import { useState } from 'react'
import { Button, CircularProgress, Tooltip } from '@mui/material'
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { FaTwitter } from 'react-icons/fa'
import { useQuery } from '@tanstack/react-query'
import { FaRedo } from 'react-icons/fa'
import toast from 'react-hot-toast'

import CustomButton from '../custom-button'
import { TwitterApi } from '@/api/twitter/twitter'
import { lang } from '@/utils/lang'

import type { LangRes } from '@/api/type'
import type { TwitterTask } from '@/api/twitter/twitter-type'

const Twitter = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [handleing, setHandleing] = useState(0)
  const [verifying, setVerifying] = useState(0)
  const [deleteing, setDeleteing] = useState(false)

  // 获取授权信息
  const {
    data,
    refetch: refetchAddressTwiter,
    isError,
  } = useQuery({
    queryKey: [TwitterApi.getAddressTwiter.name],
    queryFn: () => TwitterApi.getAddressTwiter(),
    refetchInterval: 15_000,
  })

  let isLogin = !!data?.user_id

  if (isError) {
    isLogin = false
    console.log('取消推特')
  }

  // 获取任务列表
  const { data: list, refetch: refetchList } = useQuery({
    enabled: isLogin,
    queryKey: [TwitterApi.getTask.name],
    queryFn: () => TwitterApi.getTask(),
    refetchInterval: 5_000,
  })

  let index = list?.findIndex((item) => {
    return item.id == 1
  })

  if (index) {
    let item = list?.splice(index, 1)
    item && list?.unshift(...item)
  }
  // const rank = taskList?.task

  // // 检测中的任务
  // taskList?.session.forEach((task) => {
  //   rank?.push(task.task)
  // })

  // // 已经完成的任务
  // taskList?.done.forEach((task) => {
  //   rank?.push(task.task)
  // })
  const onDelAuth = async () => {
    try {
      setDeleteing(true)
      await TwitterApi.delTwitterOAuth()
      await refetchAddressTwiter()
      toast.success(t('x.cancel'))
    } finally {
      setDeleteing(false)
    }
  }

  const verify = async (task: TwitterTask) => {
    try {
      setVerifying(task.id)
      const data = await TwitterApi.patchTask(task.uuid!)
      console.log(data)

      refetchList()
    } catch (e) {
      console.log(e)
    } finally {
      setVerifying(0)
    }
  }

  const handleTask = async (task: TwitterTask) => {
    try {
      setHandleing(task.id)
      const { callback_url } = await TwitterApi.putTask(task.id)
      refetchList()
      return window.open(callback_url)
    } catch (e) {
      console.log(e)
    } finally {
      setHandleing(0)
    }
  }

  const handleTaskButtonStatus = (task: TwitterTask) => {
    const loading = handleing == task.id
    const isVerifying = verifying == task.id

    // 申请按钮
    if (task.status == null && task.status != 0) {
      return (
        <Button
          color="error"
          variant="contained"
          className="!rounded-full !w-full !shadow-none !text-white"
          disabled={loading}
          onClick={() => handleTask(task)}
        >
          {loading ? (
            <CircularProgress
              size={14}
              color="inherit"
              style={{ marginRight: 6 }}
            ></CircularProgress>
          ) : null}
          {/* <BiL1ogoTelegram size={18} color="white" className="mr-1"></BiL1ogoTelegram> */}
          {task.button_text.zh}
        </Button>
      )
    }

    // 验证按钮
    if (task.status === 0) {
      return (
        <Button
          variant="contained"
          className="!rounded-full !w-full !bg-blue-500 !shadow-none !text-white"
          disabled={isVerifying}
          onClick={() => verify(task)}
        >
          {isVerifying ? (
            <CircularProgress
              size={14}
              color="inherit"
              style={{ marginRight: 6 }}
            ></CircularProgress>
          ) : null}
          {/* <BiLogoTelegram size={20} color="white" className="mr-1"></BiLogoTelegram> */}
          {t('verify')}
        </Button>
      )
    }

    // 提示 验证中
    if (task.status === 1) {
      return (
        <div className="text-center text-primary">
          <div>{t('verification')}...</div>
          <div>{t('estimate')}</div>
        </div>
      )
    }

    // 提示 已经完成
    return (
      <div className="text-blue-700 text-center text-primary font-bold">
        <div>{t('task.completed')}</div>
        <div>{t('award.earned')}</div>
      </div>
    )
  }

  const tryPraseJson = (str = '') => {
    let errMsg: string

    try {
      const { en, zh } = JSON.parse(str) as LangRes

      errMsg = lang.isEnglish() ? en : zh
    } catch (error) {
      errMsg = str || ''
    }

    return errMsg
  }

  const handleTaskStatus = (task: TwitterTask) => {
    if (task.status == 2) {
      return (
        <div className="mt-1 flex items-center text-sm">
          <MdOutlineCheckBox size={14} />
          <span className="ml-1">{t('completed')}</span>
        </div>
      )
    }

    if (task.status == 1) {
      return (
        <div className="mt-1 flex items-center text-sm">
          <MdCheckBoxOutlineBlank size={14} />
          <span className="ml-1 ">{t('verifying')}</span>
        </div>
      )
    }

    const errMsg = tryPraseJson(task.error_message)

    if (errMsg) {
      return (
        <Tooltip title={errMsg} arrow placement="top">
          <div className="mt-1 flex items-center text-sm cursor-pointer">
            <span className="text-xs text-nowrap truncate text-red">{errMsg}</span>
          </div>
        </Tooltip>
      )
    }

    if (task.status == 0) {
      return (
        <div className="mt-1 flex items-center text-sm">
          <MdCheckBoxOutlineBlank size={14} />
          <span className="ml-1 ">{t('wait-verify')}</span>
        </div>
      )
    }

    return (
      <div className="mt-1 flex items-center text-sm">
        <MdCheckBoxOutlineBlank size={14} />
        <span className="ml-1">{t('wait')}</span>
      </div>
    )
  }

  const taskList = () => {
    if (!isLogin) {
      return (
        <div className="mt-6 max-w-main mx-auto">
          <CustomButton
            onClick={async () => {
              try {
                setLoading(true)
                const data = await TwitterApi.getAddressTwiter()
              } catch (e) {
                console.error(e)

                const data = await TwitterApi.postTwitterOAuth()

                open(data?.url as string)
              } finally {
                setLoading(false)
              }
            }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress color="inherit" size={16} className="mr-2"></CircularProgress>
            ) : null}
            {t('x.auth')}
          </CustomButton>
        </div>
      )
    }

    return (
      <div className="mt-14 mx-auto max-w-[800px] grid grid-cols-3 gap-8 max-lg:grid-cols-2 gap-y-16 items-end">
        {list?.map((item, i) => {
          console.log(item)
          return (
            <div key={i} className="p-3 bg-white bg-opacity-50 text-sm">
              <div className="border border-black h-ful ">
                <div className="relative h-[150px]">
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <img
                    src={item.image_url}
                    alt="logo"
                    className="absolute h-[200px] -top-14 left-[50%] translate-x-[-50%] object-cover"
                  />
                  <div className="absolute bottom-0 w-full flex justify-between bg-yellow px-2 py-1 text-black">
                    <span>{item.title.zh}</span>
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                      src="/images/box-1.png"
                      alt="logo"
                      className="absolute -bottom-3 right-2 h-[60px]"
                    />
                  </div>
                </div>
                <div className="relative">
                  <div
                    className="flex items-center py-4 px-2 min-h-[70px]"
                    dangerouslySetInnerHTML={{ __html: item.content.zh }}
                  ></div>
                  {item.status != 2 && item.status !== null ? (
                    <div className="mt-2">
                      <div
                        className="absolute bottom-[-6px] right-2 flex items-center text-red cursor-pointer"
                        onClick={() => window.open(item.callback_url)}
                      >
                        <FaRedo class="mr-1 w-[10px] h-[10px]" /> {t('redo')}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="p-2 flex justify-between border-t border-black">
                  <span className="text-zinc-600">{t('rewards')}</span>
                  <div className="flex items-center text-blue-800 text-primary">
                    +{/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                      src="/images/fish-coin.png"
                      alt="token"
                      width={22}
                      height={22}
                      className="mx-[2px]"
                    />
                    {item.reward} Fish
                  </div>
                </div>
                <div className="flex justify-between border-t border-black">
                  <div className="py-1 px-2 w-[43%] h-full border-r border-black">
                    <div className="text-zinc-600">{t('status')}</div>
                    {handleTaskStatus(item)}
                  </div>
                  <div className="py-1 px-2 flex-1 self-center">{handleTaskButtonStatus(item)}</div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className="my-10 max-sm:hidden " id="x">
      <div className="flex items-center mt-10 text-2xl font-bold mx-auto text-white max-w-main">
        <FaTwitter size={36} className="mr-2"></FaTwitter>
        Twitter{t('mine')}
      </div>

      {isLogin && data?.screen_name ? (
        <div className="mt-5 pb-3 mx-auto max-w-main flex justify-between items-center">
          <>
            <div className="text-white">
              {t('x.current')}：{data?.screen_name}
            </div>
            {/* <CustomButton
              variant="black"
              onClick={onDelAuth}
              className={`${
                deleteing ? 'pointer-events-none opacity-50' : 'cursor-pointer'
              } text-sm flex items-center !bg-yellow !text-black`}
            >
              {deleteing ? (
                <CircularProgress color="info" size={12} className="mr-2"></CircularProgress>
              ) : null}
              <span className="text-black">取消授权</span>
            </CustomButton> */}
          </>
        </div>
      ) : null}
      {taskList()}

      <div className="text-gray-50 text-center mt-5 text-sm">
        {t('x.invalid1')}
        <br></br>
        {t('x.invalid2')}
      </div>
    </div>
  )
}

export default Twitter
