import React from 'react'
import Header from './header'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  showHeader?: boolean
}

const Layout = (props: Props) => {
  const { className, children, showHeader = true } = props

  // className="after:bg-[url(/images/bg.jpeg)] after:fixed after:inset-0 after:bg-center after:bg-cover after:backdrop-blur-sm"

  return (
    <div>
      {showHeader && <Header />}
      <main className={`mx-auto relative z-10 ${className}`}>{children}</main>
    </div>
  )
}

export default Layout
