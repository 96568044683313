import { useContext, useEffect } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useReadContract, useWaitForTransactionReceipt, useWriteContract } from 'wagmi'
import { useTranslation } from 'react-i18next'
import toast from 'react-hot-toast'

import { nftConfig } from './../contract/nft'
import { NftApi } from '@/api/nft/nft'
import { useSignMsg } from './use-sign-msg'

import type { Address } from 'viem'
import { HomeContext } from '@/contexts/home'

export const useMint = () => {
  const { t } = useTranslation()
  const { isSigned, signMsg } = useSignMsg()
  const { refetchAll } = useContext(HomeContext)!

  // 查询是否可 Mint
  const { data: mintIsActive } = useReadContract({ ...nftConfig, functionName: 'isActive' })

  // 查询 NFT 价格
  const { data: nftPrice } = useReadContract({ ...nftConfig, functionName: 'nftPrice' })

  // Mint NFT
  const { data: mintTx, isPending: isMinting1, writeContractAsync, reset } = useWriteContract({})

  // 等待 Mint 状态
  const {
    isSuccess,
    isError,
    isLoading: isMinting2,
  } = useWaitForTransactionReceipt({
    hash: mintTx,
  })

  // 将 Mint hash 提交给接口
  const { mutateAsync, isPending: isMinting3 } = useMutation({
    mutationKey: [NftApi.uploadNftHash.name],
    mutationFn: (hash: NonNullable<typeof mintTx>) => NftApi.uploadNftHash(hash),
  })

  // 处理 Mint
  const mintNFT = async (addr: Address | string | undefined) => {
    try {
      if (!addr) {
        toast.error(t('addr-err'))
        return
      }

      if (!isSigned) {
        toast.error(t('not-sign'))
        signMsg()
        return
      }

      await writeContractAsync({
        ...nftConfig,
        functionName: 'mintNFT',
        args: [BigInt(1), addr as Address],
      })
      toast.success(t('submit-success'))
    } catch (error) {
      reset()
      if (String(error).includes('Exceeds the maximum')) {
        toast.error(t('mint-exceeds-max'))
        return
      }

      if (String(error).includes('User rejected')) {
        toast.error(t('user-reject'))
        return
      }
      console.error('[Mint Error]: ', error)
      toast.error(t('submit-err'))
    }
  }

  if (isSuccess) {
    toast.success(t('mint-success'))
    reset()
    refetchAll()
  }

  if (isError) {
    toast.error(t('mint-err'))
    reset()
    refetchAll()
  }

  useEffect(() => {
    if (mintTx && isSuccess) {
      mutateAsync(mintTx)
    }
  }, [mintTx, isSuccess])

  return {
    mintIsActive,
    isMinting: isMinting1 || isMinting2 || isMinting3,
    nftPrice,
    mintNFT,
  }
}
