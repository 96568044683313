import React, { memo, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material'
import { useAudio } from 'react-use'
import { IoClose } from 'react-icons/io5'
import { isDesktop, isMobile } from 'react-device-detect'

import Recording from './recording'
import Recorded from './recorded'
import Microphone from './microphone'
import FishToken from '../fish-token'
import CustomButton from '../custom-button'
import { useRecord } from '@/hooks/use-record'
import { useVoice } from '@/hooks/use-voice'
import { fmt } from '@/utils/format'
import { WordMiningContext } from '@/contexts/word-mining'
import { useGlobalStore } from '@/store/global'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  setWordMiningOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const WordMining = (props: Props) => {
  const { className, setWordMiningOpen } = props
  const { t } = useTranslation()
  const useRecordReturns = useRecord()
  const { currentNftId } = useGlobalStore()
  const { fileType, blobData, audioSrc, isRecording, isRecorded, isAuthorized, resetRecord } =
    useRecordReturns
  const useVoiceReturns = useVoice({
    fileType,
    file: blobData,
  })
  const { wordResult, voiceResult, isSucces, isLimited, newVoice, resetVoiceResult } =
    useVoiceReturns
  const [audioEl, audioState, audioCtrls] = useAudio({ src: audioSrc ?? '' })

  const awardAmount = 1200
  const awardToken = 'Fish'

  const WordMiningReward = ({ className }: React.HtmlHTMLAttributes<HTMLParagraphElement>) => (
    <p className={`text-gray-400 mb-3 ${className}`}>
      {t('word-mining.subtitle').replace('{}', String(awardAmount)).replace('{}', awardToken)}
    </p>
  )

  const WordMiningContent = ({ className }: React.HtmlHTMLAttributes<HTMLParagraphElement>) => (
    <p className={`font-bold ${className}`}>
      “{isLimited ? t('record-limit') : wordResult?.dialogue.content.cn}”
    </p>
  )

  const WordMiningLevel = useMemo(() => {
    const score = fmt.percent(voiceResult?.confidence, { toNum: true }) as number

    // 及格
    if (score >= 88) {
      return (
        <>
          <div className="text-4xl font-bold text-primary">
            {score} {t('score')}
          </div>
          <p className="mt-2 text-center font-bold">
            {t('passed').split('$')[0]} <br />
            {t('passed').split('$')[1]}
          </p>
          <div className="mt-2 text-xl text-primary font-bold flex items-center">
            + {voiceResult?.reward ?? 0} <FishToken className="ml-1" />
          </div>
          {voiceResult?.reward_ext.length &&
            voiceResult?.reward_ext.map((e) => (
              <div
                key={e.foods_id}
                className="text-red flex flex-col justify-center items-center text-center"
              >
                <p className="my-3 font-bold">
                  {t('word-mining-wufu').replace('{}', String(e.name.cn))}
                </p>
                <img src={e.logo} alt="img" className="max-w-40 max-sm:max-w-20" />
              </div>
            ))}
          <CustomButton
            variant="black"
            className="mt-4"
            onClick={() => {
              resetVoiceResult()
              setWordMiningOpen(false)
            }}
          >
            {t('claim')}
          </CustomButton>
        </>
      )
    }

    // 不及格
    return (
      <>
        <div className="text-4xl font-bold text-red">
          {score} {t('score')}
        </div>
        <p className="mt-2 text-center font-bold">{t('not-passed')}</p>
        <CustomButton
          variant="black"
          className="mt-10"
          onClick={() => {
            resetVoiceResult()
            const retryCount = voiceResult?.retries ?? 0
            if (retryCount <= 0) {
              setWordMiningOpen(false)
            }
          }}
        >
          {t('try-again')}

          <span className="text-xs text-gray-400">
            ({t('retry-count')} {voiceResult?.retries ?? 0} {t('times')})
          </span>
        </CustomButton>
      </>
    )
  }, [voiceResult])

  useEffect(() => {
    if (currentNftId !== -1) {
      newVoice(currentNftId)
    }
  }, [currentNftId])

  useEffect(() => {
    if (isSucces) resetRecord()
  }, [isSucces])

  return (
    <WordMiningContext.Provider
      value={{
        ...useRecordReturns,
        ...useVoiceReturns,
        audioState,
        audioCtrls,
      }}
    >
      <div
        className={`rounded-lg p-4 flex flex-col items-center justify-between flex-1 ${className}`}
      >
        {audioEl}
        <h2 className="font-bold text-lg mb-3">{t('word-mining')}</h2>
        <WordMiningReward />
        {!isRecording && !isRecorded && (
          <video
            src={wordResult?.dialogue.url}
            className="rounded-lg mb-4 h-44 lg:h-72"
            controls
            autoPlay
            muted
          ></video>
        )}
        <WordMiningContent />

        {(isRecording || isRecorded) && <Recording />}
        {isRecorded ? <Recorded /> : <Microphone />}
        {isDesktop && !isAuthorized && (
          <p className="text-blue font-bold my-2">{t('voice-pc-tips')}</p>
        )}
        {isMobile && (
          <p className="text-gray-400 font-bold text-sm my-2">
            {t('voice-okx-tip')}({t('okx-min')}) <br />
            {t('voice-mobile-tips')}
          </p>
        )}
      </div>

      <Dialog
        fullWidth
        open={!!voiceResult}
        onClose={() => {
          newVoice(currentNftId)
          resetVoiceResult()
        }}
        classes={{
          root: '!max-w-[100vw] ',
          paper: `!max-w-[50vw] max-sm:!max-w-[100vw]
          relative max-sm:!m-0 max-sm:!w-[94vw]`,
        }}
      >
        <DialogTitle align="center">{t('word-mining-train')}</DialogTitle>
        <IconButton
          classes={{ root: '!absolute right-0 top-0' }}
          onClick={() => resetVoiceResult()}
        >
          <IoClose size={isMobile ? 18 : 20} />
        </IconButton>
        <DialogContent classes={{ root: '!flex !flex-col !items-center' }}>
          <WordMiningReward />
          <WordMiningContent className="mb-4" />
          {WordMiningLevel}
        </DialogContent>
      </Dialog>
    </WordMiningContext.Provider>
  )
}

export default WordMining
