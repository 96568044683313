import { useRouter } from 'next/router'
import { useQuery } from '@tanstack/react-query'

import { UserApi } from '@/api/user/user'
import { useSignMsg } from './use-sign-msg'

export const useUserInfo = () => {
  const { query } = useRouter()
  const { isSigned } = useSignMsg()

  // 获取用户信息
  const { data: userInfo, refetch: refetchUserInfo } = useQuery({
    queryKey: [UserApi.getUserInfo.name, isSigned],
    queryFn: () => UserApi.getUserInfo(),
    refetchInterval: 5_000,
    refetchOnMount: true,
  })

  // 根据邀请码获取用户信息
  const { data: inviterInfo, refetch: refetchInviterInfo } = useQuery({
    queryKey: [UserApi.getUserInfoByCode.name, query.i],
    queryFn: () => {
      if (!query.i) return null

      return UserApi.getUserInfoByCode(query.i as string)
    },
  })

  return { userInfo, inviterInfo, refetchUserInfo, refetchInviterInfo }
}
