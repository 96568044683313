import { useEffect } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import {
  useAccount,
  useBalance,
  useReadContract,
  useWaitForTransactionReceipt,
  useWriteContract,
} from 'wagmi'
import { type Address, parseEther, formatEther } from 'viem'
import toast from 'react-hot-toast'

import { BankApi } from '@/api/bank/bank'
import { useBalanceStore } from '@/store/balance'
import { bankConfig } from '@/contract/bank'
import { fishTokenAddress } from '@/contract/ca'
import BigNumber from 'bignumber.js'

export const useBankInfo = () => {
  const { t } = useTranslation()
  const { address } = useAccount()
  const { data } = useBalance({ address })

  const { balance, setBalance } = useBalanceStore()

  // // 获取可充值代币
  // const { data: bankTokens, refetch: refetchBankTokens } = useQuery({
  //   queryKey: [BankApi.getBank.name],
  //   queryFn: () => BankApi.getBank(),
  //   refetchInterval: 10_000,
  // })

  // 获取我的游戏资产
  const { data: myBankMine, refetch: refetchBankreMine } = useQuery({
    queryKey: [BankApi.getBankreMine.name],
    queryFn: () => BankApi.getBankreMine(),
    refetchInterval: 5_000,
  })

  const refetchBalance = async () => {
    await refetchBankreMine()
  }

  useEffect(() => {
    if (myBankMine) setBalance(myBankMine)
  }, [myBankMine, setBalance])

  // 提现提现 hash
  const { mutateAsync: uploadWithdrawHash, isPending: isWithdrawing1 } = useMutation({
    mutationKey: [BankApi.postBankreWithdraw],
    mutationFn: (hash: string | Address) => BankApi.postBankreWithdraw({ hash }),
  })

  // 查询 Gas fee
  const { refetch: refetchWithdrawGas } = useReadContract({
    ...bankConfig,
    functionName: 'drawGas',
  })

  // 提现
  const {
    data: withdrawTx,
    isPending: isWithdrawing2,
    writeContractAsync,
    reset,
  } = useWriteContract({})

  // 等待提现状态
  const {
    isSuccess,
    isLoading: isWithdrawing3,
    isError,
  } = useWaitForTransactionReceipt({
    hash: withdrawTx,
  })

  // 处理提现
  const withdraw = async (value: number) => {
    try {
      toast.loading('请求提现中，请稍后...')
      const { data: gas } = await refetchWithdrawGas()

      console.log(formatEther(data!.value), formatEther(gas!))

      if (
        gas &&
        data?.value &&
        BigNumber(formatEther(data.value)).lt(BigNumber(formatEther(gas)).plus(0.00015))
      ) {
        toast.dismiss()
        toast.error('钱包Gas费不足')
        return
      }

      const balance = myBankMine?.list[0].balances ?? 0

      if (value > balance || value <= 0) {
        toast.dismiss()
        toast.error(t('withdraw-invalid'))
        return
      }

      await writeContractAsync({
        ...bankConfig,
        functionName: 'withDraw',
        args: [fishTokenAddress, parseEther(String(value))],
        value: gas,
      })
      toast.dismiss()
      toast.success(t('submit-success'))
    } catch (error) {
      console.error('[Withdraw Error]', error)
      toast.dismiss()
      toast.error(`${t('submit-err')}: ${error}`)
      reset()
    }
  }

  if (isSuccess) {
    uploadWithdrawHash(withdrawTx!).then((data) => {
      toast.success(t('withdraw.success'))
    })
    reset()
  }

  if (isError) {
    toast.error(t('withdraw.failed'))
    reset()
  }

  return {
    myBankMine: balance,
    isWithdrawing: isWithdrawing1 || isWithdrawing2 || isWithdrawing3,
    isWithdrawSuccess: isSuccess,
    refetchBankreMine: refetchBalance,
    withdraw,
  }
}
