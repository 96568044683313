import BigNumber from 'bignumber.js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MdContentCopy } from 'react-icons/md'
import { GiMining } from 'react-icons/gi'
import CopyToClipboard from 'react-copy-to-clipboard'
import toast from 'react-hot-toast'

import CustomButton from '@/components/custom-button'
import CustomSuspense from '@/components/custom-suspense'
import { INVITE_LINKS } from '@/config/constants'
import { useUserInfo } from '@/hooks/use-user-info'
import { useTeam } from '@/hooks/use-team'
import { useResponsive } from '@/hooks/use-responsive'
import { lang } from '@/utils/lang'

const DreamTeam = () => {
  const { t } = useTranslation()
  const { userInfo, refetchUserInfo } = useUserInfo()
  const { inviteList, rewardList, isLoadingInviteList, isClaiming, claimReward } = useTeam()
  const { isMobile } = useResponsive()

  const inviteLink = INVITE_LINKS.index + (userInfo?.code || '')
  const directInvite = userInfo?.son_count ?? 0
  const indirectInvite = (userInfo?.offspring_count ?? 0) - directInvite
  const waitingClaim = BigNumber(userInfo?.reward ?? 0).toFormat()
  const totalGain = BigNumber(userInfo?.total_reward ?? 0).toFormat()

  const claim = async () => {
    await claimReward()
    refetchUserInfo()
  }

  return (
    <div className="border-2 rounded my-8 p-4 max-sm:p-2">
      <h2 className="flex items-center font-bold text-2xl text-white">
        <img src="/images/team.png" alt="team" className="max-w-12" />
        <span className="ml-2">{t('dream-team')}</span>
      </h2>
      {/* 邀请链接 */}
      <div className="my-4 flex items-center justify-between max-sm:flex-col max-sm:my-2">
        <div className="text-white max-sm:w-full">
          <p className="font-bold text-lg max-sm:truncate w-full">
            {t('invite-link')}: {inviteLink}
          </p>
          <p className="mt-3 max-sm:mt-0">{t('invite-desc')}</p>
        </div>
        <CopyToClipboard text={inviteLink} onCopy={() => toast.success(t('copy-success'))}>
          <CustomButton
            size={isMobile ? 'md' : 'lg'}
            className="ml-6 max-sm:ml-0 max-sm:mt-2 !text-base text-nowrap"
          >
            <MdContentCopy className="mr-2" />
            {t('copy-invite')}
          </CustomButton>
        </CopyToClipboard>
      </div>
      {/* 邀请分成 */}
      <div className="flex items-center max-sm:mt-6 max-sm:justify-between max-sm:w-full">
        <div className={`flex items-center text-white ${lang.isEnglish() ? 'max-sm:mr-2' : ''}`}>
          <span className={`text-4xl font-bold ${lang.isEnglish() ? 'max-sm:text-2xl' : ''}`}>
            {directInvite} {t('people')}
          </span>
          <div
            className={`flex flex-col justify-between ml-2 ${
              lang.isEnglish() ? 'max-sm:text-sm' : ''
            }`}
          >
            <span>{t('direct-invite')}</span>
            <span>20%{t('divide')}</span>
          </div>
        </div>
        <div className={`flex items-center text-white ml-10 max-sm:ml-0`}>
          <span className={`text-4xl font-bold ${lang.isEnglish() ? 'max-sm:text-2xl' : ''}`}>
            {indirectInvite} {t('people')}
          </span>
          <div
            className={`flex flex-col justify-between ml-2 ${
              lang.isEnglish() ? 'max-sm:text-sm' : ''
            }`}
          >
            <span>{t('indirect-invite')}</span>
            <span>10%{t('divide')}</span>
          </div>
        </div>
      </div>
      {/* 总收益 */}
      <div className="my-4 flex items-stretch justify-between max-sm:flex-col">
        <div className="flex items-center">
          <img src="/images/fish-coin.png" alt="fish" className="max-w-20" />
          <div className="text-white text-2xl font-bold flex flex-col justify-between ml-3">
            <span>
              {t('waiting-claim')}: {waitingClaim} Fish
            </span>
            <span>
              {t('total-gain')}: {totalGain} Fish
            </span>
          </div>
        </div>
        <CustomButton
          className="self-center ml-10 max-sm:ml-0 max-sm:mt-2"
          size={isMobile ? 'md' : 'lg'}
          disabled={userInfo?.reward == 0}
          loading={isClaiming}
          onClick={claim}
        >
          <GiMining className="mr-2" />
          {t('claim-income')}
        </CustomButton>
      </div>
      {/* 收益动态 */}
      <h4 className="text-lg font-bold text-white mb-2">
        {t('income-dync')}
        <span
          className="font-normal text-sm ml-2 cursor-pointer hover:underline transition-all"
          onClick={() => {}}
        >
          {t('readmore')}
        </span>
      </h4>
      <CustomSuspense
        isInitial={isLoadingInviteList}
        fallback={<p className="text-gray-200">{t('loading')}</p>}
        nullback={<p className="text-gray-200">{t('no-data')}</p>}
      >
        {rewardList.map((i) => (
          <div key={i.id} className="flex justify-between text-white mt-1 cursor-pointer">
            <span className="truncate">
              {i.name} {i.remark.cn}，{t('income-for-me')} ${i.amount} Fish{t('income')}
            </span>
            <span className="shrink-0 ml-2">{i.created_at}</span>
          </div>
        ))}
      </CustomSuspense>
    </div>
  )
}

export default DreamTeam
