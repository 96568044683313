import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import toast from 'react-hot-toast'
import { isDesktop } from 'react-device-detect'

export type AudioType = `audio/${string}`

let cachedStream: MediaStream

export const useRecord = () => {
  const { t } = useTranslation()
  const [recorder, setRecorder] = useState<MediaRecorder>()
  const [audioSrc, setAudioSrc] = useState<string>()
  const [blobData, setBlobData] = useState<Blob>()
  const [recordSeconds, setRecordSeconds] = useState(0)
  const [isRecording, setIsRecording] = useState(false)
  const [fileType, setFileType] = useState<AudioType>()
  const [isRecorded, setIsRecorded] = useState(false)
  const [isAuthorized, setIsAuthorized] = useState(false)
  const timerRef = useRef<NodeJS.Timeout>()

  const getStream = async () => {
    // TP钱包反复请求 `getUserMedia` 会闪退，缓存一下
    if (cachedStream) {
      return cachedStream
    }

    const newStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    })
    cachedStream = newStream

    return newStream
  }

  // 开始录制
  const startRecording = async () => {
    try {
      const stream = await getStream()
      const mediaRecoder = new MediaRecorder(stream)
      let _chunks: BlobPart | undefined

      mediaRecoder.addEventListener('start', () => {
        audioSrc && URL.revokeObjectURL(audioSrc)
        setIsRecording(true)
        setRecordSeconds(0)
        setIsRecorded(false)
        setAudioSrc(undefined)
      })
      mediaRecoder.addEventListener('dataavailable', ({ data }) => {
        if (data.size > 0) _chunks = data
      })
      mediaRecoder.addEventListener('stop', () => {
        const type = (_chunks as Blob)?.type.split(';')?.[0] ?? 'audio/webm'
        const blob = new Blob([_chunks!], { type })
        const url = URL.createObjectURL(blob)

        stopRecording()
        setAudioSrc(url)
        setBlobData(blob)
        setIsRecording(false)
        setIsRecorded(true)
        setFileType(type as AudioType)
        _chunks = undefined
      })

      mediaRecoder.start()
      startTimer()
      setRecorder(mediaRecoder)
    } catch (error) {
      const e = error as { message?: string }

      // 用户未授权
      if (e?.message?.includes('Permission denied')) {
        toast.error(t('mic-not-allowed'))
        getMicrophonePermission()
        return
      }

      toast.error(String(e))
    }
  }

  // 停止录制
  const stopRecording = () => {
    recorder?.stop()
    stopTimer()
    setRecorder(undefined)
    setAudioSrc(undefined)
    setBlobData(undefined)
    setFileType(undefined)
  }

  // 开始计时
  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setRecordSeconds((prev) => prev + 1)
    }, 1 * 1000)
  }

  // 停止计时（不会重置计时）
  const stopTimer = () => {
    timerRef.current && clearInterval(timerRef.current)
  }

  // 重置所有状态
  const resetRecord = () => {
    audioSrc && URL.revokeObjectURL(audioSrc)
    stopTimer()
    setIsRecording(false)
    setIsRecorded(false)
    setRecorder(undefined)
    setAudioSrc(undefined)
    setBlobData(undefined)
    setFileType(undefined)
  }

  // 获取麦克风权限
  const getMicrophonePermission = async () => {
    console.log('get permission')
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true, video: false })

      setIsAuthorized(true)
    } catch (error) {
      setIsAuthorized(false)
    }
  }

  useEffect(() => {
    // 电脑端在一开始的时候就检测用户是否授权麦克风
    // 因为只有电脑端需要使用 isAuthorized 判断从而进行提示
    // 如果手机端也用这个反复检测的话，TP钱包会闪退
    if (isDesktop) {
      getMicrophonePermission()
    }
  }, [])

  return {
    recorder,
    fileType,
    blobData,
    audioSrc,
    recordSeconds,
    isRecording,
    isRecorded,
    isAuthorized,
    startRecording,
    stopRecording,
    resetRecord,
    resetRecordSeconds: () => setRecordSeconds(0),
  }
}
