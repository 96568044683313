import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DialogContent, DialogTitle } from '@mui/material'
import { useAccount } from 'wagmi'

import CustomButton from '../custom-button'
import CustomDialog from '../custom-dialog'
import { useSignMsg } from '@/hooks/use-sign-msg'
import { HomeContext } from '@/contexts/home'
import { InviteDialog } from '../invite-dialog'

const NoNFT = () => {
  const { t } = useTranslation()
  const { address } = useAccount()
  const { isSigned, signMsg } = useSignMsg()
  const { refetchNftInfo, isRefetchingNftInfo } = useContext(HomeContext)!

  return (
    <div className="mt-20 flex flex-col justify-center items-center text-lg text-white max-sm:p-3 max-sm:text-base">
      <img src="/images/咸鱼1.png" alt="fish" className="w-[200px] h-[200px] rounded-lg" />
      <div className="mt-2 font-bold">{t('fish.p2')}</div>
      <div className="mt-2 font-bold">
        {t('fish.desc2').split('$')[0]}
        <InviteDialog className="text-3xl text-yellow cursor-pointer max-sm:text-2xl">
          {t('fish.desc2').split('$')[1]}
        </InviteDialog>
        {t('fish.desc2').split('$')[2]}
      </div>
      <div className="mt-2 font-bold">{t('fish.p4')}</div>

      {address && !isSigned && (
        <>
          <div className="mt-6 mb-2 font-bold">
            <span className="text-2xl text-yellow">{t('has-nft').split('$')[0]}</span>
            {t('has-nft').split('$')[1]}
          </div>
          <CustomButton
            className="text-black"
            onClick={async () => {
              await signMsg()
              refetchNftInfo()
            }}
            loading={isRefetchingNftInfo}
          >
            {t(isRefetchingNftInfo ? 'processing' : 'sign')}
          </CustomButton>
        </>
      )}

      <img
        src="/images/mint介绍.png"
        alt="mint intro"
        className="max-w-[660px] my-4 -ml-10 max-sm:max-w-full sm:-translate-x-8"
      />
    </div>
  )
}

export default NoNFT
