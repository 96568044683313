import { useQuery } from '@tanstack/react-query'

import { FoodsApi } from '@/api/foods/foods'

export const useBackpack = () => {
  const {
    data: foods,
    isLoading: isLoadingFoods,
    isRefetching: isRefetchingFoods,
    isFetching,
    refetch: refetchFoods,
  } = useQuery({
    queryKey: [FoodsApi.getUseFoods.name],
    queryFn: () => FoodsApi.getUseFoods({ status: 0 }),
  })

  return {
    foods: foods?.[3],
    isLoadingFoods: isLoadingFoods || isFetching,
    isRefetchingFoods,
    refetchFoods,
  }
}
