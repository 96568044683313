import { ContentType, api } from '../fetch'

import type { GetVoiceRes, NewVoiceRes, ResponseBase, VoiceUploadReq } from './types'

export class VoiceApi {
  /*** 获取状态 */
  static getVoice(id: string) {
    return api.get<GetVoiceRes>('voice', { id }, false)
  }

  /***
   * 生成语音
   * - `token_id`: nft 的id
   * - `debugMode`: 为 `true` 开启 `debug` 模式，不限次数
   **/
  static newVoice(token_id: number, debugMode = false) {
    const params = { token_id } as Record<string, string | number>
    if (debugMode) {
      params.secret_code = '超级梦想家'
    }
    return api.put<NewVoiceRes>('voice', params, false)
  }

  /*** 上传语音 */
  static uploadVoice(params: VoiceUploadReq) {
    return api.post<ResponseBase>('voice', params, ContentType.Json, false)
  }
}
