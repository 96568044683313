import React, { useContext, useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogContent, DialogTitle, IconButton, Skeleton, TextField } from '@mui/material'
import { IoClose } from 'react-icons/io5'

import CustomButton from '../custom-button'
import { useBankInfo } from '@/hooks/use-bank-info'
import { HomeContext } from '@/contexts/home'
import CustomDialog from '../custom-dialog'
import { useBackpack } from '@/hooks/use-backpack'
import { GoodsInfo } from '@/api/shop/shop-type'
import CustomSuspense from '../custom-suspense'

const MyAssets = () => {
  const { t } = useTranslation()
  const { userInfo } = useContext(HomeContext)!
  const { myBankMine, isWithdrawing, isWithdrawSuccess, withdraw } = useBankInfo()
  const [withdrawOpen, setWithdrawOpen] = useState(false)
  const [value, setValue] = useState('0')
  const [backpackOpen, setBackpackOpen] = useState(false)

  const balanceToken = myBankMine?.list[0]

  // 提现后自动关闭 Dialog
  useEffect(() => {
    if (isWithdrawSuccess) {
      setWithdrawOpen(false)
    }
  }, [isWithdrawSuccess])

  return (
    <div className="flex items-stretch my-6">
      <div className="relative cursor-pointer" onClick={() => setBackpackOpen(true)}>
        <img
          src="/images/backpack.png"
          alt="backpack"
          className="max-w-14 max-h-14 mr-2 max-sm:max-w-10 max-sm:max-h-10"
        />
        <p className="absolute -bottom-3 left-0 text-sm text-white max-sm:text-xs max-sm:bottom-1 max-sm:-left-0.5 whitespace-nowrap">
          {t('my-backpack')}
        </p>
      </div>
      <FoodDetailDialog open={backpackOpen} onClose={() => setBackpackOpen(false)} />
      <img
        src="/images/fish-coin.png"
        alt="fish token"
        className="max-w-16 max-h-16 max-sm:max-w-10 max-sm:max-h-10"
      />
      <div className="flex flex-col justify-between text-2xl font-bold text-white ml-3">
        <h3>{BigNumber(balanceToken?.balances ?? 0).toFormat()} Fish</h3>
        <h4 className="text-xl max-sm:text-lg whitespace-nowrap">
          {t('total')}
          {BigNumber(userInfo?.obtain_amount ?? 0).toFormat()} Fish
        </h4>
      </div>
      <CustomButton
        className="self-center ml-4 whitespace-nowrap"
        onClick={() => setWithdrawOpen(true)}
      >
        {t('withdraw')}
      </CustomButton>

      {/* 提现 dialog */}
      <Dialog
        fullWidth
        classes={{ paper: '!relative' }}
        open={withdrawOpen}
        onClose={() => setWithdrawOpen(false)}
      >
        <DialogTitle>{t('withdraw-title')}</DialogTitle>
        <IconButton
          classes={{ root: '!absolute top-0 right-0' }}
          onClick={() => setWithdrawOpen(false)}
        >
          <IoClose />
        </IconButton>
        <DialogContent classes={{ root: '!flex !items-start' }}>
          <TextField
            size="small"
            type="number"
            disabled={!balanceToken?.balances}
            value={value}
            onChange={({ target }) => {
              const n = Number(target.value)
              const balance = Number(balanceToken?.balances ?? 0)

              if (n > balance) return

              setValue(target.value)
            }}
            helperText={`${t('total-withdrawable')}: ${BigNumber(
              balanceToken?.balances ?? 0
            ).toFormat()}`}
            sx={{
              '& .MuiFormHelperText-root': {
                marginLeft: 0,
              },
            }}
          />
          <CustomButton
            className="ml-4 whitespace-nowrap"
            loading={isWithdrawing}
            disabled={!balanceToken?.balances}
            onClick={() => withdraw(Number(value))}
          >
            {t('withdraw')}
          </CustomButton>
        </DialogContent>
      </Dialog>
    </div>
  )
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  open: boolean
  onClose: () => void
}

const FoodDetailShimmer = () => {
  return (
    <div className="flex justify-between w-full">
      {Array.from({ length: 3 }).map((_, i) => (
        <Skeleton
          key={i}
          variant="rounded"
          className="!block !w-36 !h-36 max-sm:!w-28 max-sm:!h-28"
        />
      ))}
    </div>
  )
}

const FoodDetailDialog = (props: Props) => {
  const { open, onClose } = props

  // 此判断放在 `useBackpack` 上面，可以保证每次打开背包都会请求最新背包数据
  // 从而每次打开背包都能看到最新数据，无需手动刷新。
  if (!open) {
    return <></>
  }

  const { t } = useTranslation()
  const { foods, isLoadingFoods } = useBackpack()
  const [foodDetail, setFoodDetail] = useState<GoodsInfo>()

  return (
    <>
      <CustomDialog open={open} onClose={onClose} onCloseButtonClick={onClose} isRed fullWidth>
        <DialogTitle align="center" classes={{ root: '!font-bold' }}>
          {t('backpack')}
        </DialogTitle>
        <DialogContent classes={{ root: '!flex !items-center !flex-wrap' }}>
          <CustomSuspense
            isInitial={isLoadingFoods || foods?.length === 0}
            fallback={<FoodDetailShimmer />}
            className="w-full !flex !items-center !flex-wrap"
            nullback={<p className="my-6 text-gray-400 text-center w-full ">{t('no-foods')}</p>}
          >
            {foods?.map((f, i) => (
              <div
                key={i}
                className="relative max-w-36 max-sm:max-w-28 cursor-pointer !mx-2 mb-4"
                onClick={() => setFoodDetail(f)}
              >
                <img
                  key={f.foods_id}
                  src="/images/itembox.png"
                  alt="backpack"
                  className="w-full mr-2"
                />
                <img
                  src={f.logo}
                  alt="img"
                  className="absolute left-1/2 top-3 -translate-x-1/2 max-w-16 max-sm:max-w-12 "
                />
                <div className="text-yellow font-bold absolute right-4 bottom-2 max-sm:right-3 max-sm:bottom-1">
                  {f.count}
                </div>
              </div>
            ))}
          </CustomSuspense>
        </DialogContent>
      </CustomDialog>

      {/* 物品详情 dialog */}
      <CustomDialog
        open={!!foodDetail}
        onClose={() => setFoodDetail(undefined)}
        onCloseButtonClick={() => setFoodDetail(undefined)}
        isRed
        fullWidth
      >
        <DialogTitle align="center" classes={{ root: '!font-bold !text-yellow' }}>
          {foodDetail?.name.cn}
        </DialogTitle>
        <DialogContent classes={{ root: '!flex !flex-col !justify-center !items-center' }}>
          <p>X {foodDetail?.count ?? 0}</p>
          <img src={foodDetail?.logo} alt="img" className="max-h-96 mt-2" />
          <p className=" mt-4">{foodDetail?.description.cn}</p>
          <CustomButton onClick={() => setFoodDetail(undefined)} className="!text-black mt-4">
            {t('confirm')}
          </CustomButton>
        </DialogContent>
      </CustomDialog>
    </>
  )
}

export default MyAssets
