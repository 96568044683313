import { BankApi } from '@/api/bank/bank'
import { BankMineRes } from '@/api/bank/bank-type'
import { create } from 'zustand'

interface BalanceStore {
  balance?: BankMineRes
  setBalance: (data: BankMineRes) => Promise<any>
}

export const useBalanceStore = create<BalanceStore>((set) => ({
  balance: undefined,
  setBalance: async (data: BankMineRes) => set({ balance: data }),
}))
