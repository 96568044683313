import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title?: React.ReactNode
  titleClass?: string
}

const ImgLoading = (props: Props) => {
  const { className = '', title, titleClass } = props
  const { t } = useTranslation()

  return (
    <div className={`flex flex-col justify-center items-center p-3 ${className}`}>
      <p className={`text-primary text-center font-bold text-xl ${titleClass}`}>
        {title ?? t('processing')}
      </p>
      <img src="/images/loading.gif" alt="loading" className="mt-10" />
    </div>
  )
}

export default ImgLoading
