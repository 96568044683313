import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { watchAccount } from '@wagmi/core'

import Buttons from '@/components/buttons'
import MyAssets from '@/components/home/fish-total'
import MergeNFT from '@/components/home/merge-nft'
import NoNFT from '@/components/home/no-nft'
import Profile from '@/components/home/profile'
import Layout from '@/components/layout'
import NegativeStatus from '@/components/negative-status'
import DreamTeam from '../components/team'
import HomeShimmer from '@/components/home/shimmer'
import { useUserInfo } from '@/hooks/use-user-info'
import { useGlobalStore } from '@/store/global'
import { useNftInfo } from '@/hooks/use-nft-info'
import { HomeContext } from '@/contexts/home'
import { config } from '@/config/wagmi'
import Twitter from '@/components/home/twitter'

const Home = () => {
  const { query } = useRouter()
  const [hasInviteLink, setHasInviteLink] = useState(false)
  const userInfoReturn = useUserInfo()
  const nftInfoReturn = useNftInfo()
  const { isPending } = nftInfoReturn
  const { currentNft } = useGlobalStore()
  const { nftBalance } = nftInfoReturn

  useEffect(() => {
    setHasInviteLink(!!query.i)
  }, [query])

  useEffect(() => {
    const unwatch = watchAccount(config, {
      onChange: (account, prevAccount) => {
        if (!prevAccount.address) return
        if (account.address !== prevAccount.address) {
          // 处理切换账户
          location.reload()
          // refetchAll()
        }
      },
    })

    return unwatch
  }, [])

  const Game = () => {
    return currentNft ? (
      <>
        <div className="max-w-main mx-auto max-sm:px-3">
          <Profile />
          <MyAssets />
          <NegativeStatus />
          <Buttons />
          <DreamTeam />
        </div>
        <Twitter />
      </>
    ) : hasInviteLink && !nftBalance ? (
      <MergeNFT />
    ) : (
      <NoNFT />
    )
  }

  return (
    <HomeContext.Provider value={{ ...userInfoReturn, ...nftInfoReturn }}>
      {/* <Layout showHeader={false}>
        {!hasInviteLink && nftBalance && isPending ? <HomeShimmer /> : Game()}
      </Layout> */}
      <Layout showHeader={false}>{Game()}</Layout>
    </HomeContext.Provider>
  )
}

export default Home
