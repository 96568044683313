import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import toast from 'react-hot-toast'

import { VoiceApi } from '@/api/voice'
import { parser } from '@/utils/parser'
import { VoiceLang } from '@/api/voice/types'
import { useGlobalStore } from '@/store/global'

import type { AudioType } from './use-record'
import type { NewVoiceRes, GetVoiceRes } from '@/api/voice/types'

interface Params {
  fileType: AudioType | undefined
  file: Blob | undefined
}

export const useVoice = (params: Params) => {
  const { file } = params
  const { t } = useTranslation()
  const timerRef = useRef<number>()
  const { currentNftId } = useGlobalStore()
  const [wordResult, setWordResult] = useState<NewVoiceRes['data']>()
  const [voiceResult, setVoiceResult] = useState<GetVoiceRes['data']>()
  const [isUploading, setIsUploading] = useState(false)
  const [isParsing, setIsParsing] = useState(false)
  const [isSucces, setIsSuccess] = useState(false)
  const [voiceLang, setVoiceLang] = useState(VoiceLang.Zh)
  const [isLimited, setIsLimited] = useState(false)

  const resetVoiceResult = () => setVoiceResult(undefined)

  const resetVoice = () => {
    stopPolling()
    setIsParsing(false)
    setIsSuccess(false)
    setIsUploading(false)
  }

  const shouldNewVoice = (key: string) => ['DONE', 'FAILURE', 'START'].includes(key)

  // 生成一次台词
  const newVoice = async (tokenId: number | undefined) => {
    console.log('newVoice', process.env.NODE_ENV)

    if (tokenId === undefined) return

    try {
      const isDev = process.env.NODE_ENV === 'development'
      const { data } = await VoiceApi.newVoice(tokenId, isDev) // true 开启 debug 模式，无限语音

      console.log('newVoice response', data, 'shouldNewVoice?', shouldNewVoice(data.status))

      setWordResult(data)
      setIsLimited(false)

      // 拥有会话，则继续请求上一个会话的结果
      if (!shouldNewVoice(data.status)) {
        startPolling()
        setIsParsing(true)
      }
    } catch (error) {
      const e = error as { code?: number }

      if (e?.code === -400006) {
        setIsLimited(true)
      }
    }
  }

  // 上传音频文件
  const uploadVoice = async () => {
    if (!file || !wordResult?.id) {
      toast.error(t('voice-error'))
      return
    }

    try {
      setIsUploading(true)
      setIsSuccess(false)

      const base64 = await parser.blob2Base64(file)
      const { code, message } = await VoiceApi.uploadVoice({
        id: wordResult.id,
        data: base64,
        language: voiceLang,
      })

      if (code === 0) {
        toast.success(`${t('upload-success')}, ${t('parsing')}...`)
        startPolling()
        return
      }

      toast.error(t('upload-failed') + message)
    } finally {
      setIsUploading(false)
    }
  }

  // 获取音频结果
  const getVoiceResult = async () => {
    if (!wordResult?.id) {
      toast.error('[getVoiceResult Erorr]: ${}')
      return
    }

    try {
      setIsParsing(true)
      const { code, data } = await VoiceApi.getVoice(wordResult.id)

      console.log('getVoiceResult response', data, 'shouldNewVoice?', shouldNewVoice(data.status))

      if (code < 0) {
        toast.error(t('parse-failed'))
        stopPolling()
        setIsParsing(false)
        return
      }

      // 这些状态代表成功
      if (shouldNewVoice(data.status)) {
        stopPolling()
        setVoiceResult(data)
        return
      }

      // toast.error(`[Status Exception]: ${data.status}`)
    } catch (error) {
      toast.error(`[getVoiceResult Error]: ${error}`)
    }
  }

  // 轮询获取音频结果
  const startPolling = () => {
    getVoiceResult()
    timerRef.current = window.setInterval(getVoiceResult, 2_000)
  }

  // 停止轮询
  const stopPolling = () => {
    setIsParsing(false)
    timerRef.current && clearInterval(timerRef.current)
  }

  useEffect(() => {
    // 得到结果后重置，不要在请求中重置，否则每次都会重置
    if (voiceResult) {
      setIsParsing(false)
      setIsSuccess(true)
    }
  }, [voiceResult])

  return {
    wordResult,
    voiceResult,
    isUploading,
    isParsing,
    isSucces,
    voiceLang,
    isLimited,
    uploadVoice,
    getVoiceResult,
    stopPolling,
    newVoice,
    setVoiceLang,
    resetVoiceResult,
    resetVoice,
  }
}
