import React, { useContext, useState } from 'react'
import { IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FaPencilAlt } from 'react-icons/fa'
import { FaCheck } from 'react-icons/fa6'
import { IoClose } from 'react-icons/io5'
import toast from 'react-hot-toast'

import { useNftStatus } from '@/hooks/use-nft-status'
import { dom } from '@/utils/dom'
import { useBankInfo } from '@/hooks/use-bank-info'
import { useResponsive } from '@/hooks/use-responsive'
import { useGlobalStore } from '@/store/global'
import { fmt } from '@/utils/format'
import { HomeContext } from '@/contexts/home'

const Profile = () => {
  const { t } = useTranslation()
  const [isEditingName, setIsEditingName] = useState(false)
  const [isEditingDream, setIsEditingDream] = useState(false)
  const { userInfo, nftInfo, isUpdating, updateNftInfo, refetchNftInfo, refetchUserInfo } =
    useContext(HomeContext)!
  const { currentNft, currentNftId } = useGlobalStore()
  const { refetchBankreMine } = useBankInfo()
  const [name, setName] = useState(nftInfo?.name || '')
  const [dream, setDream] = useState(nftInfo?.custom_dream || '')
  const { isNegative } = useNftStatus()
  const { isMobile } = useResponsive()

  const NegativeState = ({ className = '' } = {}) => {
    return (
      <div className={`font-bold ${className}`}>
        {isNegative() ? (
          <>
            <img src="/images/broken_heart.png" className={`max-w-6 mr-1`} />
            <span>{t('emotion-negative')}</span>
          </>
        ) : (
          <>
            <img src="/images/heart.png" className={`max-w-6 mr-1 mt-0.5`} />
            <span>{t('emotion-normal')}</span>
          </>
        )}
      </div>
    )
  }

  // 设置名称
  const onRename = async () => {
    if (!currentNft?.token_id || isUpdating) return

    await updateNftInfo({
      token_id: currentNft?.token_id,
      name,
    })
    await refetchNftInfo()
    await refetchBankreMine()
    setIsEditingName(false)
  }

  // 设置梦想
  const onSetDream = async () => {
    if (!currentNftId) {
      toast.error(t('not-nft-id'))
      return
    }
    if (isUpdating) {
      toast.error(t('updating'))
      return
    }

    try {
      toast.loading(t('updating'))

      await updateNftInfo({
        token_id: currentNftId,
        custom_dream: dream,
      })

      toast.dismiss()
      toast.success(t('update-success'))

      await refetchUserInfo()
      await refetchNftInfo()
      await refetchBankreMine()
    } catch (error) {
      toast.success(t('update-failed'))
    } finally {
      setIsEditingDream(false)
    }
  }

  return (
    <div className="flex items-stretch mt-8 max-sm:mt-4 max-sm:flex-col max-sm:items-center">
      {/* eslint-disable-next-line @next/next/no-img-element */}
      {/* <img src={nftInfo?.avatar} alt="avatar" className="max-w-40 max-h-40 rounded-md mr-4" /> */}
      <video
        src={nftInfo?.animation_url}
        className="max-w-40 max-h-40 rounded-md mr-4"
        muted
        autoPlay
        controls
      ></video>

      <div className="flex flex-col justify-between max-sm:items-center max-sm:w-full">
        <h2 className="text-white font-bold flex items-center cursor-pointer max-sm:my-0 max-sm:max-h-[34px]">
          {isEditingName ? (
            <>
              <input
                type="text"
                placeholder={t('set-name')}
                className={`rounded-md text-black font-normal px-2 max-sm:max-w-28 max-sm:text-sm max-sm:py-[0.05rem] ${dom.class(
                  isUpdating,
                  'opacity-50'
                )}`}
                value={name}
                onChange={({ target }) => setName(target.value)}
              />
              <IconButton onClick={onRename} color="inherit">
                <FaCheck size={isMobile ? 14 : 18} />
              </IconButton>
              <IconButton onClick={() => setIsEditingName(false)} color="inherit">
                <IoClose size={isMobile ? 18 : 20} />
              </IconButton>
            </>
          ) : (
            <>
              <span className={`text-2xl`}>{nftInfo?.name}</span>
              <IconButton onClick={() => setIsEditingName(true)} color="inherit">
                <FaPencilAlt size={18} />
              </IconButton>
            </>
          )}
          {/* 负面状态 */}
          {!isMobile && <NegativeState className="flex items-center ml-2" />}
        </h2>
        {isMobile && <NegativeState className="text-white flex items-center -ml-6" />}
        <div className="grid grid-cols-2 gap-x-10 text-white text-lg font-bold max-sm:text-base max-sm:w-full">
          <div className="col-span-2 flex items-center">
            {t('dream')}:
            {isEditingDream ? (
              <div className="flex items-center ml-1">
                <input
                  type="text"
                  placeholder={t('set-dream')}
                  className={`rounded-md text-black font-normal px-1.5 py-1 max-h-[21px] text-sm ${dom.class(
                    isUpdating,
                    'opacity-50'
                  )}`}
                  value={dream}
                  onChange={({ target }) => setDream(target.value)}
                />
                <FaCheck
                  className="mx-3 cursor-pointer transition-all hover:text-secondary"
                  size={isMobile ? 14 : 18}
                  onClick={onSetDream}
                />
                <IoClose
                  className="cursor-pointer transition-all hover:text-secondary"
                  size={isMobile ? 18 : 22}
                  onClick={() => setIsEditingDream(false)}
                />
              </div>
            ) : (
              <span
                className="underline decoration-2 cursor-pointer ml-1 hover:text-secondary"
                onClick={() => setIsEditingDream(true)}
              >
                {nftInfo?.has_dream
                  ? nftInfo.custom_dream
                  : t('set-dream-income').replace('{}', String(1000)).replace('{}', 'Fish')}
              </span>
            )}
          </div>
          <span>
            {t('rank')}: {userInfo?.rank}
          </span>
          <span className="ml-2 max-sm:ml-1">
            {t('good-evil')}: {nftInfo?.enthusiasm}
          </span>
          <span>
            {t('soul')}: {fmt.ellipsis(nftInfo?.yuanshen?.zh)}
          </span>
          <span className="ml-2 max-sm:ml-1 truncate">
            {t('crush')}: {fmt.ellipsis(nftInfo?.love.zh)}
          </span>
          <span>
            {t('profession')}: {fmt.ellipsis(nftInfo?.job.zh)}
          </span>
          <span className="ml-2 max-sm:ml-1 truncate">
            {t('hobby')}: {fmt.ellipsis(nftInfo?.hobby.zh)}
          </span>
        </div>
      </div>
    </div>
  )
}

export default Profile
