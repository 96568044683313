import React, { useContext } from 'react'
import Link from 'next/link'
import toast from 'react-hot-toast'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'

import CustomCard from './custom-card'
import CustomButton from './custom-button'
import { INVITE_LINKS } from '@/config/constants'
import { useNftStatus } from '@/hooks/use-nft-status'
import { useGlobalStore } from '@/store/global'
import { HomeContext } from '@/contexts/home'
import { useGameChat } from '@/hooks/use-game-chat'

const NegativeStatus = () => {
  const { t } = useTranslation()
  const { currentNftId } = useGlobalStore()
  const { isNegative } = useNftStatus()
  const { nftInfo } = useContext(HomeContext)!
  const { negativeDesc } = useGameChat()

  const inviteLink = `${INVITE_LINKS.chat}${currentNftId}`
  const copyText = `${negativeDesc}

${t('must-friend')}
  
${inviteLink}`

  if (!isNegative()) return <></>

  return (
    <CustomCard variant="shadow" className="bg-white flex items-start">
      <img src="/images/broken_heart.png" alt="broken" className="max-sm:max-w-10" />
      <div className="ml-4 mt-2 max-sm:mt-0">
        <h3 className="text-xl font-bold mb-3 max-sm:mb-1">{t('negative-title')}</h3>
        <p className="mb-3 max-sm:mb-1">{negativeDesc}</p>
        <p className="mb-3 max-sm:mb-1">{t('must-friend')}</p>
        <div className="flex items-center max-sm:flex-col max-sm:items-start">
          <CopyToClipboard text={copyText} onCopy={() => toast.success(t('copy-success'))}>
            <Link
              href={inviteLink}
              className="text-blue-600 underline"
              onClick={(e) => e.preventDefault()}
            >
              {inviteLink}
            </Link>
          </CopyToClipboard>
          <CopyToClipboard text={copyText} onCopy={() => toast.success(t('copy-success'))}>
            <CustomButton className="ml-4 max-sm:ml-0 max-sm:mt-2">
              {t('copy-with-friend')}
            </CustomButton>
          </CopyToClipboard>
        </div>
      </div>
    </CustomCard>
  )
}

export default NegativeStatus
