import { UserApi } from '@/api/user/user'
import { useInfiniteQuery, useMutation } from '@tanstack/react-query'

export const useTeam = () => {
  // 获取子级(邀请)列表
  const {
    data: inviteData,
    isLoading: isLoadingInviteList,
    isRefetching: isRefetchingInviteList,
    fetchNextPage,
  } = useInfiniteQuery({
    initialPageParam: 1,
    queryKey: [UserApi.getSonList.name],
    queryFn: ({ pageParam }) => UserApi.getSonList({ page_index: pageParam, page_size: 25 }),
    getNextPageParam: (_, __, page) => page + 1,
  })

  // 获取团队奖金记录列表
  const {
    data: teamRewardData,
    isLoading: isLoadingTeamReward,
    isRefetching: isRefetchingTeamReward,
    fetchNextPage: fetchNextTeamReward,
  } = useInfiniteQuery({
    initialPageParam: 1,
    queryKey: [UserApi.getTeamReward.name],
    queryFn: ({ pageParam }) => UserApi.getTeamReward({ page_index: pageParam, page_size: 5 }),
    getNextPageParam: (_, __, page) => page + 1,
  })

  // 领取团队奖金
  const { isPending: isClaiming, mutateAsync: claimReward } = useMutation({
    mutationKey: [UserApi.claimTeamReward.name],
    mutationFn: UserApi.claimTeamReward,
  })

  return {
    teamRewardData,
    inviteData,
    inviteList: inviteData?.pages.flatMap((i) => i.list) ?? [],
    rewardList: teamRewardData?.pages.flatMap((i) => i.list) ?? [],
    isLoadingInviteList,
    isRefetchingInviteList,
    isClaiming,
    isLoadingTeamReward,
    isRefetchingTeamReward,
    claimReward,
    fetchNextPage,
    fetchNextTeamReward,
  }
}
