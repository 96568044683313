export enum VoiceLang {
  Zh = 'cn',
  ZhTw = 'cn_cantonese',
  En = 'en',
}

type Status =
  | 'START'
  | 'UPLOADING'
  | 'UPLOADED'
  | 'RECOGNITION'
  | 'RECOGNIZED'
  | 'TO_REWARD'
  | 'REWARDING'
  | 'FAILURE'
  | 'DONE'

export interface ResponseBase<T = null> {
  code: number
  message: string
  status: number
  data: T
}

export type GetVoiceRes = ResponseBase<{
  id: string // 给定的session-id (经过校验的重返响应)
  /**
   * 会话的状态
   * ```
    {
      "START": "Session created successfully",
      "UPLOADING": "Voice file uploading",
      "UPLOADED": "Voice file uploaded",
      "RECOGNITION": "Voice file recognition created",
      "RECOGNIZED": "Voice file recognition completed",
      "TO_REWARD": "Scoring for recognition results",
      "REWARDING": "Rewarding for scoring results",
      "FAILURE": "Session failed, could retry",
      "DONE": "Session ended"
    }
   * ``` 
   */
  status: Status
  /**
   * 表示相似度
   * ```
    当 confidence >= 0.99: Prefect
    当 0.99 > confidence >= 0.95: Excellent
    当 0.95 > confidence >= 0.90: Good
    当 0.90 > confidence: Needs improvement
   * ```
   */
  confidence: number
  grade: string // 表示相似度的等级
  reward: number | null // 表示奖励数, 只有DONE且confidence>=0.9才有
  reward_ext: {
    // 如果有额外奖励将为一个数组, 没有为空
    foods_id: number // 道具的id
    count: number // 道具的数量
    name: {
      cn: string
      en: string
    } // 道具的ISO-639-1语言->名字映射体
    logo: string // 道具的logo(好像是url)
  }[]
  retries: number // (仅当STATUS为FAILURE) 剩余的重试次数，不会返回0次结果，为0的时候会直接切到DONE
}>

export type NewVoiceRes = ResponseBase<{
  id: string
  status: Status
  dialogue: {
    url: string
    content: {
      cn: string
      cn_cantonese: string
    }
  }
}>

export interface VoiceUploadReq {
  id: string
  data: string
  language: VoiceLang
}
