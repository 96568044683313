import { ContentType, chatApi } from '../fetch'

import type { LangReq } from '../type'
import type {
  ChatAuthParams,
  ChatParams,
  GetNftStatusReq,
  GetNftStatusRes,
  GetRewardReq,
  GetRewardRes,
  GetStatusLogRes,
} from './types'

export class ChatApi {
  /** 正常聊天 */
  static normalChat(params: ChatParams & LangReq, overrideAuth: ChatAuthParams) {
    return chatApi.post<ReadableStream>(
      '/fish-chat-normal',
      params,
      ContentType.Json,
      true,
      overrideAuth,
      true
    )
  }

  /** 游戏模式聊天 */
  static gameChat(params: ChatParams & LangReq, overrideAuth: ChatAuthParams) {
    return chatApi.post<ReadableStream>(
      '/fish-chat-game',
      params,
      ContentType.Json,
      true,
      overrideAuth,
      true
    )
  }

  /** 获取聊天的蛊惑/救助历史列表 */
  static getStatusLog(params: { token_id: number } & LangReq) {
    return chatApi.post<GetStatusLogRes[]>(
      '/get-fish-status-log',
      params,
      ContentType.Json,
      true,
      undefined,
      false
    )
  }

  /** 获取 NFT 的当前状态 */
  static getNftStatus(params: GetNftStatusReq & LangReq) {
    return chatApi.post<GetNftStatusRes>(
      '/get-fish-nft-status',
      params,
      ContentType.Json,
      true,
      undefined,
      false
    )
  }

  /** 根据地址获取奖励 */
  static getReward(params: GetRewardReq & LangReq) {
    return chatApi.post<GetRewardRes>(
      '/get-fish-reward',
      params,
      ContentType.Json,
      false,
      undefined,
      false
    )
  }
}
