import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { IoPauseOutline, IoPlayOutline } from 'react-icons/io5'

import { WordMiningContext } from '@/contexts/word-mining'

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export const Recording = (props: Props) => {
  const { className } = props
  const { t } = useTranslation()
  const { audioCtrls, audioState, isRecording, isRecorded, recordSeconds } =
    useContext(WordMiningContext)!

  return (
    <div className={`mt-4 h-44 w-full flex flex-col justify-center items-center ${className}`}>
      <div className="border-2 border-black p-2 rounded-2xl w-52 h-14 flex justify-center items-center ">
        {isRecording ? (
          <img src="images/wave.gif" alt="wave" className="h-10" />
        ) : (
          <>
            <IoPlayOutline
              className={`cursor-pointer ${audioState.playing ? 'hidden' : ''}`}
              size={38}
              onClick={audioCtrls.play}
            />
            <IoPauseOutline
              className={`cursor-pointer ${!audioState.playing ? 'hidden' : ''}`}
              size={38}
              onClick={audioCtrls.pause}
            />
          </>
        )}
      </div>
      {isRecorded ? (
        <p className="mt-2 font-bold">
          {t('recorded')}
          {recordSeconds}s
        </p>
      ) : (
        <p className="mt-2 font-bold">
          {t('plz-speak')}({recordSeconds}s)...
        </p>
      )}
    </div>
  )
}

export default Recording
