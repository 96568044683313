import { api } from '../fetch'
import { BaseReq } from '../type'
import {
  GetSonListRes,
  GetTeamRankRes,
  GetTeamRewardRes,
  GetTokenRankRes,
  GetUserInfoByCodeRes,
  LegionTokenLogReq,
  Pagination,
  PowerLeaderboardReq,
  PowerLeaderboardRes,
  UpdateUserReq,
  UserBackpackReq,
  UserBackpackRes,
  UserInfoReq,
  UserInviteListReq,
  UserInviteListRes,
  UserLegionTokenLogReq,
  UserMacaoReq,
  UserMacaoRes,
} from './user-type'

export class UserApi {
  /**
   * 获取用户信息
   */
  static getUserInfo(query?: BaseReq) {
    return api.get<UserInfoReq>('user', query)
  }

  /**
   * 更新用户信息
   */
  static putUserInfo(query?: BaseReq) {
    return api.put<UpdateUserReq>('user', query)
  }

  /**
   * 获取用户邀请列表
   */
  static getUserInviteList(query?: UserInviteListReq) {
    return api.get<UserInviteListRes>('user/son/list', query)
  }

  /**
   * 获取用户背包信息
   */
  static getUserBackpack(query?: UserBackpackReq) {
    return api.get<UserBackpackRes>('useuser/backpackr', query)
  }

  /**
   * 参与澳门
   */
  static postUserMacao(query?: UserMacaoReq) {
    return api.post<UserMacaoRes>('user/macao', query)
  }

  /**
   * 获取战力排行榜
   */
  static getUserPowerLeaderboard(query?: PowerLeaderboardReq) {
    return api.get<PowerLeaderboardRes>('user/power/leaderboard', query)
  }

  /**
   * 获取军团收益历史记录
   */
  static getLegionTokenLog(query?: LegionTokenLogReq) {
    return api.get<UserLegionTokenLogReq>('user/legion/token/log', query)
  }

  /**
   * 根据邀请码获取用户信息
   */
  static getUserInfoByCode(code?: string) {
    return api.get<GetUserInfoByCodeRes>('user/code', { code })
  }

  /**
   * 获取子级列表
   */
  static getSonList(query?: Pagination) {
    return api.get<GetSonListRes>('user/son/list', query)
  }

  /**
   * 领取团队奖金
   */
  static claimTeamReward() {
    return api.post<null>('user/reward/withdraw')
  }

  /**
   * 获取团队奖金记录
   */
  static getTeamReward(query?: Pagination) {
    return api.get<GetTeamRewardRes>('user/reward/log', query)
  }

  /**
   * 获取团队奖金排行榜
   */
  static getTeamRank(query?: Pagination) {
    return api.get<GetTeamRankRes>('user/reward/rank', query)
  }

  /**
   * 获取产币排行榜
   */
  static getTokenRank(query?: Pagination) {
    return api.get<GetTokenRankRes>('user/power/rank', query)
  }
}
