import React from 'react'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  isInitial: boolean
  isRefetching?: boolean
  fallback: React.ReactNode
  nullback?: React.ReactNode
}

/**
 * 处理从服务器获取数据的 `DOM` 结构,
 * 在初始加载时使用骨架屏, 后续加载减少透明度而不是使用骨架屏,
 * 达到更好的用户体验。
 * - `isInitial`: 是否为初始加载
 * - `isRefetching`: 是否为获取中(这不包括初始加载)
 * - `fallback`: 初始加载时使用的骨架屏或任意你想要的组件
 * @example 这是一个与 `React-Query` 配合的示例
 * ```
 * <CustomSuspense
 *   // 使用 isLoading 作为初始加载
 *   isInitial={isLoadingList || !list}
 *   // 后续的加载使用 isRefetching
 *   isRefetching={isRefetchingList}
 *   // 初始骨架屏放在这
 *   fallback={<ListShimmer />}
 * ></CustomSuspense>
 * ```
 */
export const CustomSuspense = (props: Props) => {
  const { isInitial, isRefetching, nullback, fallback, children, className } = props
  const isNull = Array.isArray(children) ? !children.length : !children

  return (
    <div className={className}>
      {isInitial ? (
        fallback
      ) : (
        <div className={`${isRefetching ? 'opacity-50' : ''} ${className}`}>
          {isNull ? nullback : children}
        </div>
      )}
    </div>
  )
}

export default CustomSuspense
