import { DialogTitle, DialogContent } from '@mui/material'
import { t } from 'i18next'
import CustomDialog from './custom-dialog'
import { BasicProps } from '@/type/type'
import { useState } from 'react'

export const InviteDialog = (props: BasicProps) => {
  const [tipsOpen, setTipsOpen] = useState(false)
  return (
    <>
      <span className={`${props.className}`} onClick={() => setTipsOpen(true)}>
        {props.children}
      </span>
      <CustomDialog
        fullWidth
        open={tipsOpen}
        onClose={() => setTipsOpen(false)}
        onCloseButtonClick={() => setTipsOpen(false)}
      >
        <DialogTitle>{t('fish-tips')}</DialogTitle>
        <DialogContent>{t('fish.p5')}</DialogContent>
      </CustomDialog>
    </>
  )
}
