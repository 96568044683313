export interface ChatResponseAnswer {
  answer_type: 'chat_stream' | 'address_stream' | 'value_stream'
  text: string
  hyper_text: string
  meta: string
}

interface ParseStreamStringOnParsed {
  (data: ChatResponseAnswer): void
}

export const parser = {
  stream2String(str: string, onParsed: ParseStreamStringOnParsed) {
    const arr = str.trim().split('\n').filter(Boolean)
    return arr
      .map((m) => {
        const parsed = JSON.parse(m.replace('data: ', '')) as ChatResponseAnswer
        onParsed(parsed)
        return parsed
      })
      .flat()
      .join('')
  },
  blob2Base64(blob: Blob | undefined) {
    return new Promise<string>((resolve, reject) => {
      if (!blob) return reject('Blob is null')

      const reader = new FileReader()

      reader.addEventListener('error', () => reject('Reader error'))
      reader.addEventListener('load', () => {
        const base64Str = reader.result?.toString().split(',')[1]

        resolve(base64Str ?? '')
      })
      reader.readAsDataURL(blob)
    })
  },
}
