import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@mui/material'
import { MdOutlineCancel } from 'react-icons/md'
import { IoMdSend } from 'react-icons/io'

import { WordMiningContext } from '@/contexts/word-mining'
import ImgLoading from '../img-loading'
import CustomDialog from '../custom-dialog'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  onSend?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onCancel?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const Recorded = (props: Props) => {
  const { className } = props
  const { t } = useTranslation()
  const {
    isUploading,
    isParsing,
    audioCtrls,
    uploadVoice,
    resetRecord,
    resetRecordSeconds,
    resetVoice,
  } = useContext(WordMiningContext)!
  const [processingOpen, setProcessingOpen] = useState(false)

  // 发送
  const onSend = () => {
    uploadVoice()
  }

  // 取消发送
  const onCancel = () => {
    audioCtrls.pause()

    resetRecord()
    resetRecordSeconds()
    resetVoice()
  }

  useEffect(() => {
    setProcessingOpen(isUploading || isParsing)
  }, [isUploading, isParsing])

  return (
    <div className={`flex mt-24 mb-1 max-sm:mt-[5.5rem] ${className}`}>
      <button
        className={`flex flex-col items-center mr-20 justify-between cursor-pointer
        transition-all duration-300 lg:hover:text-gray-400 active:text-gray-400`}
        onClick={onSend}
      >
        {isUploading || isParsing ? (
          <>
            <CircularProgress color="info" />
            <span className="font-bold mt-1 text-info">
              {isParsing ? t('parsing') : t('uploading')}...
            </span>
          </>
        ) : (
          <>
            <IoMdSend size={44} />
            <span className="font-bold mt-1">{t('send-mining')}</span>
          </>
        )}
      </button>
      <button
        className={`flex flex-col items-center justify-between cursor-pointer
        transition-all duration-300 lg:hover:text-gray-400 active:text-gray-400`}
        onClick={onCancel}
      >
        <MdOutlineCancel size={44} />
        <span className="font-bold mt-1">{t('cancel')}</span>
      </button>
      {/* 处理中 */}
      <CustomDialog
        open={processingOpen}
        // open={true}
        fullWidth
        paperClass="!items-center"
        onCloseButtonClick={() => setProcessingOpen(false)}
      >
        <ImgLoading titleClass="px-4" title={t('voice-wait')} />

        {/* <div className="my-3 text-gray-400 text-sm">
          等待太久了？
          <span className="text-primary" onClick={() => {}}>
            重新来过
          </span>
        </div> */}
      </CustomDialog>
    </div>
  )
}

export default Recorded
