import { createContext } from 'react'
import { useAudio } from 'react-use'

import { useRecord } from '@/hooks/use-record'
import { useVoice } from '@/hooks/use-voice'

type UseRecordReturns = ReturnType<typeof useRecord>

type UseVoiceReturns = ReturnType<typeof useVoice>

type AudioReturns = ReturnType<typeof useAudio>

interface ContextType extends UseRecordReturns, UseVoiceReturns {
  audioState: AudioReturns['1']
  audioCtrls: AudioReturns['2']
}

export const WordMiningContext = createContext<ContextType | null>(null)
