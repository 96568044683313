import { useRouter } from 'next/router'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useAccount } from 'wagmi'
import { useTranslation } from 'react-i18next'

import { ChatApi } from '@/api/chat'
import { useGlobalStore } from '@/store/global'
import { useStorage } from './use-storage'

export const useGameChat = (isMyNft = false) => {
  const { query } = useRouter()
  const { currentNftId } = useGlobalStore()
  const { getUuid } = useStorage()
  const { address } = useAccount()
  const { i18n } = useTranslation()

  const id = currentNftId === -1 ? 0 : currentNftId
  const currentId = Number(query.id || id)

  // 获取蛊惑/救助日志
  const { data: statusLogs, refetch: refetchStatusLogs } = useQuery({
    queryKey: [ChatApi.getStatusLog.name, currentId],
    queryFn: async () => {
      return ChatApi.getStatusLog({
        token_id: currentId,
        lang: i18n.language,
      })
    },
  })

  const testUuid = ''

  // 获取 NFT 当前状态
  const {
    data: nftStatus,
    isFetching: isFetchingNftStatus,
    isRefetching: isRefetchingNftStatus,
    refetch: refetchNftStatus,
  } = useQuery({
    queryKey: [ChatApi.getNftStatus.name, id, currentId, address],
    queryFn: () => {
      return ChatApi.getNftStatus({
        token_id: address ? id : 0,
        passive_token_id: currentId,
        address: testUuid || address || getUuid(),
        lang: i18n.language,
      })
    },
  })

  const refetchAll = () => {
    return Promise.all([refetchStatusLogs(), refetchNftStatus()])
  }

  // console.log('current status', nftStatus, '\n', 'status log', statusLogs)

  // 输入地址领奖
  const { mutateAsync } = useMutation({
    mutationKey: [ChatApi.getReward.name],
    mutationFn: ChatApi.getReward,
  })

  const claimReward = async (addr: string) => {
    const id = currentNftId === -1 ? 0 : currentNftId

    return mutateAsync({
      passive_token_id: Number(query.id || id),
      address: addr,
      uuid: testUuid || getUuid() || '',
      lang: i18n.language,
    })
  }

  const contains = (target: string, keywords: string[]) => {
    return !!keywords.filter((item) => target.includes(item)).length
  }

  const keywords = ['蛊惑', '救助', '蛊惑']

  return {
    nftStatus,
    statusLogs,
    isFetchingNftStatus,
    isRefetchingNftStatus,
    isNormal: !isMyNft && nftStatus?.nft_status === 1,
    isNegative: !isMyNft && nftStatus?.nft_status === 4,
    isPlayedAll: contains(nftStatus?.is_played_full || '', keywords),
    isPlayedWord: !!nftStatus?.is_played_word,
    canMakeTrouble: !contains(nftStatus?.is_played_word || '', ['用光']),
    canRelief: !contains(nftStatus?.is_played_word || '', ['用光']),
    negativeDesc: nftStatus?.message,
    negativeGoal: nftStatus?.user_goal,
    claimReward,
    refetchStatusLogs,
    refetchNftStatus,
    refetchAll,
  }
}
