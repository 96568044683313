import React, { useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TiMicrophoneOutline } from 'react-icons/ti'
import { useLongPress } from 'ahooks'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { useAccount } from 'wagmi'
import toast from 'react-hot-toast'

import { WordMiningContext } from '@/contexts/word-mining'
import { useNftStatus } from '@/hooks/use-nft-status'
import { useDetectWallet } from '@/hooks/use-detect-wallet'
import { useGlobalStore } from '@/store/global'

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export const Microphone = (props: Props) => {
  const { className } = props
  const { t } = useTranslation()
  const microphoneRef = useRef<HTMLDivElement>(null)
  const [notSuppotedOpen, setNotSuppotedOpen] = useState(false)
  const { isRecording, isLimited, resetRecord, startRecording, stopRecording } =
    useContext(WordMiningContext)!
  const { isDead } = useNftStatus()
  const { isNotSupported, supportedWallets } = useDetectWallet()
  const { bgmAudio } = useGlobalStore()
  const { address } = useAccount()

  const disabledClass = () => {
    if (isRecording && !isLimited) {
      return 'opacity-50 scale-90'
    }

    if (isLimited || isDead()) {
      return 'opacity-50'
    }

    return ''
  }

  const onStartRecording = () => {
    bgmAudio?.pause()

    if (!address) {
      toast.error(t('not-connect-wallet'))
      return
    }

    if (isLimited) {
      toast.error(t('record-limit'))
      return
    }

    if (isDead()) {
      toast.error(t('nft-dead'))
      return
    }

    // 检测设备是否支持
    if (isNotSupported) {
      setNotSuppotedOpen(true)
      return
    }

    startRecording()
  }

  // 长按录制
  useLongPress(onStartRecording, microphoneRef, {
    onLongPressEnd() {
      resetRecord()
      stopRecording()
    },
  })

  return (
    <div className={`mt-4 flex flex-col items-center ${className}`}>
      {/* <div className="flex items-center">
        <span className="mr-2">语言偏好:</span>
        <ToggleButtonGroup
          exclusive
          size="small"
          value={voiceLang}
          onChange={(_, lang) => lang && setVoiceLang(lang)}
        >
          <ToggleButton value={VoiceLang.Zh}>国语</ToggleButton>
          <ToggleButton value={VoiceLang.ZhTw}>粤语</ToggleButton>
        </ToggleButtonGroup>
      </div> */}
      <div
        className={`mt-4 mb-4 border-2 border-black p-6 lg:hover:opacity-50
        rounded-full bg-yellow cursor-pointer transition-all ${disabledClass()}`}
        ref={microphoneRef}
      >
        <TiMicrophoneOutline size={40} />
      </div>
      <p className={`font-bold select-none ${isLimited || isDead() ? 'opacity-50' : ''}`}>
        {isDead() ? t('nft-dead') : t('press-speak')}
      </p>
      <Dialog open={notSuppotedOpen} onClose={() => setNotSuppotedOpen(false)}>
        <DialogTitle>{t('device-not-supported')}</DialogTitle>
        <DialogContent>
          {t('not-supported')}
          {supportedWallets.map((w, i) => (
            <div key={i} className="font-bold first:mt-2">
              {w.name}
            </div>
          ))}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Microphone
