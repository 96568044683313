import React from 'react'

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  variant?: 'outlined' | 'shadow' | 'outliend-shadow'
  isActive?: boolean
  title?: React.ReactNode
  hover?: boolean
  active?: boolean
}

export const CustomCard = (props: Props) => {
  const {
    className,
    children,
    variant = 'outlined',
    isActive = false,
    title,
    hover,
    active,
    onClick,
  } = props

  const isShadow = variant !== 'outlined'

  const computedClass = () => {
    const classes = []

    if (hover) {
      const shadowCls = `lg:hover:translate-x-offset lg:hover:translate-y-offset
      lg:hover:shadow-none`.trim()
      const normalCls = `lg:hover:translate-x-offset-n lg:hover:translate-y-offset-n
      lg:hover:shadow-offset`.trim()

      classes.push(isShadow ? shadowCls : normalCls)
    }
    if (active) {
      const shadowCls = `active:translate-x-offset active:translate-y-offset
      active:shadow-none`.trim()
      const normalCls = `active:translate-x-offset-n active:translate-y-offset-n
      active:shadow-offset`.trim()

      classes.push(isShadow ? shadowCls : normalCls)
    }

    return classes.join(' ')
  }

  return (
    <div
      className={`border-2 border-black rounded p-3 cursor-pointer transition-all relative
      ${className} ${isActive ? `border-primary ${isShadow ? 'shadow-offset-primary' : ''}` : ''}
      ${isShadow ? 'shadow-offset' : ''}
      ${computedClass()}
      `}
      onClick={onClick}
    >
      {/* 千万不要去掉条件判断，否则会无限递归 */}
      {title && (
        <CustomCard
          variant={variant}
          className={`bg-white !absolute -top-4 left-1/2 !py-0 -translate-x-1/2 active:!-translate-x-1/2`}
        >
          {title}
        </CustomCard>
      )}
      {children}
    </div>
  )
}

export default CustomCard
