import React from 'react'

interface Props extends React.SVGProps<SVGSVGElement> {
  color?: string
  size?: number
}

const MomentIcon = (props: Props) => {
  const { className, color = '#000000', size = 24 } = props

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M31 7V24V7Z" fill="none" />
      <path
        d="M31 7V24"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6357 6.63599L30.7779 20.7781L16.6357 6.63599Z"
        fill="none"
      />
      <path
        d="M16.6357 6.63599L30.7779 20.7781"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M7 17H24H7Z" fill="none" />
      <path
        d="M7 17H24"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3643 17.636L6.22212 31.7781L20.3643 17.636Z"
        fill="none"
      />
      <path
        d="M20.3643 17.636L6.22212 31.7781"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M17 25V42V25Z" fill="none" />
      <path
        d="M17 25V42"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6357 27.636L31.7779 41.7781L17.6357 27.636Z"
        fill="none"
      />
      <path
        d="M17.6357 27.636L31.7779 41.7781"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M24 31L42 31L24 31Z" fill="none" />
      <path
        d="M24 31L42 31"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.3643 16.636L28.2221 30.7781L42.3643 16.636Z"
        fill="none"
      />
      <path
        d="M42.3643 16.636L28.2221 30.7781"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 31C27.866 31 31 27.866 31 24C31 20.134 27.866 17 24 17C20.134 17 17 20.134 17 24C17 27.866 20.134 31 24 31Z"
        fill="none"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default MomentIcon
