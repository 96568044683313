import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAccount, useSwitchChain } from 'wagmi'
import toast from 'react-hot-toast'

import CustomButton from '../custom-button'
import { useMint } from '@/hooks/use-mint'
import { fmt } from '@/utils/format'
import { useUserInfo } from '@/hooks/use-user-info'
import { config } from '@/config/wagmi'

const MergeNFT = () => {
  const { t } = useTranslation()
  const { inviterInfo } = useUserInfo()
  const { isMinting, mintNFT } = useMint()

  const chainId = config.chains[0].id
  const { chainId: id } = useAccount()
  const { switchChainAsync } = useSwitchChain()

  const handleMint = async () => {
    if (chainId != id) {
      toast.error(t('not-arb'))
      await switchChainAsync({ chainId })
    }
    mintNFT(inviterInfo?.address)
  }

  return (
    <div className="mt-20 flex flex-col justify-center items-center text-lg text-white px-3">
      <div className="mt-8 text-3xl">{t('merge.title')}</div>
      <div className="mt-6">
        {t('merge.invite')}
        {fmt.addr(inviterInfo?.address) || t('none')}
      </div>
      <div className="mt-6 flex justify-center items-center max-sm:max-w-screen">
        {/*  eslint-disable-next-line @next/next/no-img-element */}
        <img
          src="/images/咸鱼1.png"
          alt="fish"
          className="max-w-[200px] max-h-[200px] rounded-lg max-sm:max-w-28 max-sm:max-h-28"
        />
        {/*  eslint-disable-next-line @next/next/no-img-element */}
        <img
          src="/images/arrow.png"
          alt="arrow"
          className="mx-8 w-[90px] h-[90px] max-sm:w-10 max-sm:h-10"
        />
        {/*  eslint-disable-next-line @next/next/no-img-element */}
        <img
          src="/images/mint.gif"
          alt="fish"
          className="max-w-[200px] max-h-[200px] rounded-lg max-sm:max-w-28 max-sm:max-h-28"
        />
      </div>
      <CustomButton size="lg" className="text-black mt-10" loading={isMinting} onClick={handleMint}>
        {t('receive.nft')}
      </CustomButton>
      <div className="mt-4 font-bold">{t('merge.p1')}</div>
      <div className="mt-3 font-bold">{t('merge.p2')}</div>
      <img
        src="/images/mint介绍.png"
        alt="mint intro"
        className="max-w-[660px] my-4 -ml-10 max-sm:max-w-full sm:-translate-x-8"
      />
    </div>
  )
}

export default MergeNFT
