import { useNftInfo } from '@/hooks/use-nft-info'
import { useUserInfo } from '@/hooks/use-user-info'
import { createContext } from 'react'

type UserInfo = ReturnType<typeof useUserInfo>

type NftInfo = ReturnType<typeof useNftInfo>

type Props = {}

export const HomeContext = createContext<(UserInfo & NftInfo & Props) | null>(null)
