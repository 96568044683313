import { api } from '../fetch'
import { Twitter, TwitterTask } from './twitter-type'

export class TwitterApi {
  /**
   * 获取地址的推特状态
   */
  static getAddressTwiter() {
    return api.get<{
      user_id: number // 绑定的推特
      screen_name: string // 推特名(初始认证完的值,不后续更新)
    }>('twitter')
  }
  /**
   * 获取推特任务
   */
  static getTask() {
    return api.get<TwitterTask[]>('twitter/task/list')
  }
  /**
   * 申请一个任务
   */
  static putTask(task_id: number) {
    return api.put<Twitter>('twitter/task', { task_id })
  }
  /**
   * 验证一个任务
   */
  static patchTask(task_session_id: string) {
    return api.post<Twitter>('twitter/task', { task_session_id })
  }
  /**
   * 生成一个推特认证
   */
  static postTwitterOAuth() {
    return api.post<{ url?: string }>('twitter', { url: window.location.href })
  }
  /**
   * 删除一个推特认证
   */
  static delTwitterOAuth() {
    return api.del<{ url?: string }>('twitter')
  }
}
