import { useEffect, useState } from 'react'
import { isIOS, isAndroid, isChrome } from 'react-device-detect'
import toast from 'react-hot-toast'

export const useDetectWallet = () => {
  const [isNotSupported, setIsNotSupported] = useState(false)

  //支持的钱包，关键词在匹配时不区分大小写，这里仅仅是为了可读性
  const supportedWallets = [
    {
      name: '欧意(OKX)',
      keywords: ['OKApp', 'OKEx', 'OKX'],
    },
    {
      name: 'Token Pocket(TP)',
      keywords: ['TokenPocket', 'Token Pocket'],
    },
    {
      name: 'Bitget Wallet',
      keywords: ['Bitget', 'BitKeep'],
    },
    {
      name: 'MetaMask(小狐狸)',
      keywords: ['MetaMaskMobile', 'Meta Mask Mobile'],
    },
  ]

  const userAgentContains = (keywords: string[]) => {
    return keywords.some((k) => navigator.userAgent.toLowerCase().includes(k.toLowerCase()))
  }

  const isOkx = () => userAgentContains(supportedWallets[0].keywords)

  const extractOkxVersion = (str: string) => {
    const regex = /OKApp\/\(OKEx\/(\d+(\.\d+)+)\)/
    const match = str.match(regex)

    if (match && match[1]) {
      return match[1]
    }

    return ''
  }

  const handleOkx = () => {
    const version = extractOkxVersion(navigator.userAgent)
    const isNotSupported = version < '6.55.1'

    if (isNotSupported) {
      toast.error('请将您的欧意(OKX)升级为最新版本后使用(至少是v6.55.1版本)')
    }

    setIsNotSupported(isNotSupported)
  }

  useEffect(() => {
    const isSupported = userAgentContains(supportedWallets.map((w) => w.keywords).flat())

    // IOS 基本支持, 非移动端也支持
    if (isIOS || !isAndroid) return

    // 处理OKX的情况，OKX最新已支持
    if (isOkx()) {
      handleOkx()
      return
    }

    if (!isSupported && !isChrome) {
      // 判断安卓支持情况
      setIsNotSupported(true)
    }
  }, [])

  return { isNotSupported, supportedWallets }
}
