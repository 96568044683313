import { useGlobalStore } from '@/store/global'
import { useTranslation } from 'react-i18next'

enum NftStatus {
  Dead,
  Idle,
  Battling,
  Working,
  Negative,
}

export const useNftStatus = () => {
  const { t } = useTranslation()
  const { currentNft } = useGlobalStore()

  const map: Record<string, string> = {
    0: t('dead'),
    1: t('idle'),
    2: t('battling') + '...',
    3: t('working') + '...',
    4: t('nagative-status'),
  }

  // 如果调用方法时不传状态，则使用当前 NFT 的状态
  const chooseStatus = (s: number | undefined) => (s === undefined ? currentNft?.status : s) ?? -1

  return {
    getStatus: (s?: number) => map[chooseStatus(s)] ?? t('none'),
    isDead: (s?: number) => chooseStatus(s) === NftStatus.Dead,
    isIdle: (s?: number) => chooseStatus(s) === NftStatus.Idle,
    isBattling: (s?: number) => chooseStatus(s) === NftStatus.Battling,
    isWorking: (s?: number) => chooseStatus(s) === NftStatus.Working,
    isNegative: (s?: number) => chooseStatus(s) === NftStatus.Negative,
  }
}
