import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { ImBubble } from 'react-icons/im'
import { FaMicrophoneLines } from 'react-icons/fa6'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { IoClose } from 'react-icons/io5'

import CustomButton from './custom-button'
import CustomDialog from './custom-dialog'
import MomentIcon from './moment-icon'
import WordMining from '@/components/word-mining'
import { useResponsive } from '@/hooks/use-responsive'
import { useNftInfo } from '@/hooks/use-nft-info'
import { route } from '@/config/route'
import { useNftStatus } from '@/hooks/use-nft-status'
import { useGlobalStore } from '@/store/global'
import { lang } from '@/utils/lang'

const Buttons = () => {
  const { t } = useTranslation()
  const { push } = useRouter()
  const { isMobile } = useResponsive()
  const { nftInfo } = useNftInfo()
  const { isNegative } = useNftStatus()
  const [wordMiningOpen, setWordMiningOpen] = useState(false)
  const [negativeOpen, setNegativeOpen] = useState(false)
  const { currentNftId, bgmAudio } = useGlobalStore()

  useEffect(() => {
    if (!wordMiningOpen) {
      bgmAudio?.play()
    }
  }, [wordMiningOpen])

  return (
    <div className="flex items-center justify-start mt-8 max-sm:mt-4 max-sm:grid max-sm:grid-cols-2 max-sm:gap-x-2 max-sm:gap-y-3">
      <CustomButton className="mr-3" onClick={() => push(route.chat + `/${currentNftId}`)}>
        {<ImBubble className="mr-2" />}
        {t('chat')}
      </CustomButton>

      <CustomButton
        // className="max-sm:ml-2 whitespace-nowrap mr-4"
        className="whitespace-nowrap mr-3"
        onClick={() => {
          if (isNegative()) {
            setNegativeOpen(true)
            return
          }
          setWordMiningOpen(true)
        }}
      >
        {<FaMicrophoneLines className="mr-2 whitespace-nowrap shrink-0" />}
        {t('word-mining')}
        <span className="text-xs">
          ({t('word-mining-per').replace('{}', String(nftInfo?.max_voice_count ?? 5))})
        </span>
      </CustomButton>

      <CustomButton className=" max-sm:!px-0" onClick={() => push(route.moment)}>
        <MomentIcon
          className={`mr-2 shrink-0 ${lang.isEnglish() && isMobile ? 'mr-0' : ''}`}
          size={isMobile ? 16 : 24}
        />
        {t('moment-mining')}
        <span className="text-xs">
          ({t('moment-per').replace('{}', String(nftInfo?.max_moment_count || 2))})
        </span>
        {/* {nftInfo?.moment_count}/{nftInfo?.max_moment_count} */}
      </CustomButton>

      {/* {!isMobile && (
        <CustomButton className=" max-sm:ml-2" onClick={() => push('/#x')}>
          {<IoLogoTwitter className="mr-2" />}
          {t('x-mining')}
        </CustomButton>
      )} */}

      <CustomDialog
        open={negativeOpen}
        onClose={() => setNegativeOpen(false)}
        onCloseButtonClick={() => setNegativeOpen(false)}
      >
        <DialogTitle>{t('negative-title')}</DialogTitle>
        <DialogContent>{t('negative-tips')}</DialogContent>
      </CustomDialog>

      <Dialog
        fullWidth
        open={wordMiningOpen}
        onClose={() => setWordMiningOpen(false)}
        classes={{
          root: '!max-w-[100vw] ',
          paper: `!max-w-[60vw] lg:!min-h-[640px] max-sm:!max-w-[100vw]
          relative max-sm:!m-0 max-sm:!w-[94vw]`,
        }}
      >
        <IconButton
          classes={{ root: '!absolute right-0 top-0' }}
          onClick={() => setWordMiningOpen(false)}
        >
          <IoClose />
        </IconButton>
        <WordMining setWordMiningOpen={setWordMiningOpen} />
      </Dialog>
    </div>
  )
}

export default Buttons
